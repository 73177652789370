import React, { useEffect, useState } from 'react'
import "./ColorSwittcher.css"
import ColorItem from './ColorItem'

const ColorSwittcher = () => {

    const colors = ['#2d3436', '#4834d4', '#be2edd', '#fff', '#6ab04c', '#30336b'];

    const [state,setState] = useState(false);

    useEffect(() => {
        const currentColor = localStorage.getItem('color')
        // console.log(currentColor);

        setTheme(currentColor);
    },[])

    const setTheme = (color) => {
        document.documentElement.style.setProperty('--bg-color',color)
    }

    const setColor = (event) => {
        const currentColor = event.target.style.getPropertyValue('--bg-color');

        setTheme(currentColor)
        localStorage.setItem('color',currentColor);
    }

    return (
        <>

          
                <div className={`color-switcher ${state && 'color-switcher--open'}`}>
                    <button onClick={() => setState(prevState => !prevState)}>
                        <i className="fa-solid fa-gear"></i>
                    </button>
                    {/* <h1 className="heading">
                        Select Color
                    </h1> */}
                    <div className="color-list">
                        {colors.map((color, idx) => <ColorItem key={idx} setColor={setColor} color={color} />)}
                    </div>
                </div>
           

        </>
    )
}

export default ColorSwittcher