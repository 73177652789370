import React from 'react'
import { NavLink } from 'react-router-dom'

const Error = () => {
    return (
        <div className='bg-light text-center ' style={{ height: "100%" }}>
            <div className="container p-5">
                <div className="row error-container merriweather-bold">
                    <div className="col-md-12 text-center ">
                        <h1 className="error-heading mb-3">404</h1>
                        <p className="error-text mb-4">Oops! The page you are looking for could not be found.</p>
                        <div className='d-flex justify-content-center align-items-center'>
                            <NavLink className="text-decoration-none btn btn-lg btn-back fs-5 bg-primary text-white text-center " style={{ width: "200px" }} to="/" >Go Back</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error