import React from 'react'
import "./Home.css"
import Silder from './Silder'
import TernaryOperator from './TernaryOperator'
// import ColorSwittcher from './ColorSwittcher'

const Home = () => {


  return (
    <>

      <section>
        <div className="container-fluid p-0 " >
          <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
            <div className="carousel-inner FontFamilyMontSerret">
              <div className="carousel-item active  Same-Img-Size" style={{ backgroundImage: `url(${require("../Img/Silde1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ' }}>
                <div className='Main-Silder-Content'>
                  <h2>
                    Epicenter Consultants
                  </h2>
                  <h6>
                    Epicenter Consultants Are Constantly Developing Boundaries To Meet The High Expectations Of Modern Customers.
                  </h6>
                  <button>
                    Learn More
                  </button>
                </div>
              </div>

              <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../Img/Silde2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
                <div className='Main-Silder-Content'>
                  <h2>
                    Epicenter Consultants
                  </h2>
                  <h6>
                    Epicenter Consultants Are Constantly Developing Boundaries To Meet The High Expectations Of Modern Customers.
                  </h6>
                  <button>
                    Learn More
                  </button>
                </div>
              </div>

              <div className="carousel-item Same-Img-Size " style={{ backgroundImage: `url(${require("../Img/Silde3.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
                <div className='Main-Silder-Content'>
                  <h2>
                    Epicenter Consultants
                  </h2>
                  <h6>
                    Epicenter Consultants Are Constantly Developing Boundaries To Meet The High Expectations Of Modern Customers.
                  </h6>
                  <button>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <ColorSwittcher /> */}

      <section>
        <div className="container-fluid">
          <div className="container-lg FeatureMain">
            <div className="row mt-3 g-4">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className=''>
                  <img className='' src={require("../Img/HomeSecondSection1.png")} alt="icloudsoft_logo" />
                </div>
                <div className='Feature-Content'>
                  <h2>
                    Innovative Architecture
                  </h2>
                  <p>
                    We sketch and build physical models using computer visualisations in order to experiment and deliver innovative architecture.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className=''>
                  <img className='' src={require("../Img/HomeSecondSection2.png")} alt="icloudsoft_logo" />
                </div>
                <div className='Feature-Content'>
                  <h2>
                    Full Project Management
                  </h2>
                  <p>
                    We provide full project management solution to help you get your dream project completed in your budget and given time.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className=''>
                  <img className='' src={require("../Img/HomeSecondSection3.png")} alt="icloudsoft_logo" />
                </div>
                <div className='Feature-Content'>
                  <h2>
                    Maintenance and Renovation
                  </h2>
                  <p>
                    We provide building maintenance services after project completion. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid " style={{ backgroundColor: "#f9f9f9" }}>
          <div className="container-lg  FeatureMain">
            <div className='ServicesMainContent'>
              <h2>
                Services We Offer
              </h2>
              <p>
                EpicenterConsultants team has experts related to every area of construction and that enables us to provides complete range of services for any size of project.
              </p>
            </div>

            <div className="row mt-3 g-4 ">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <a href="/rccdesign" className='text-decoration-none'>
                  <div className='SingleService'>
                    <img className='w-100' src={require("../Img/HomeServices1.png")} alt="icloudsoft_logo" />
                    <div className='Services-Content'>
                      <h2>
                        RCC Design
                      </h2>
                      <p>
                        The design processes in which we sketch, build physical models and create computer visualisations in order to experiment and innovate to deliver architecture that balances contextual response with functionality.
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <a href="/structuralsteeldesign" className='text-decoration-none'>
                  <div className='SingleService'>
                    <img className='w-100' src={require("../Img/HomeServices2.png")} alt="icloudsoft_logo" />
                    <div className='Services-Content'>
                      <h2>
                        Structural Steel Design
                      </h2>
                      <p>
                        We provide full project management solution solution to help you get your dream project completed within your budget and timeline. We also provide maintenance services after project completion.
                      </p>
                    </div>
                  </div>
                </a>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <a href="/restoration" className='text-decoration-none'>
                  <div className='SingleService'>
                    <img className='w-100' src={require("../Img/HomeServices3.png")} alt="icloudsoft_logo" />
                    <div className='Services-Content'>
                      <h2>
                        Restoration / Rehabilitation / Strengthening Works
                      </h2>
                      <p>
                        Interior design is the art and science of enhancing the interiors, sometimes including the exterior, of a space or building, to achieve a healthier and more aesthetically pleasing environment for the end user.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div className='ServicesBtn'>
              <a className='text-decoration-none' href='/services'>
                <button>
                  View More Services
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid pb-lg-5" style={{ backgroundColor: "#fff" }}>
          <div className="container-lg  FeatureMain">
            <div className='ServicesMainContent'>
              <h2>
                Featured Projects
              </h2>
              <p>
                EpicenterConsultants team has worked over many awesome projects over the years. You can check out few of those below.
              </p>
            </div>
          </div>
          <Silder />
        </div>
      </section>

      <TernaryOperator />

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#FFD800" }}>
          <div className="container-lg FeatureMain">
            <div className="row d-flex justify-content-center align-items-center MainText">
              <div className="col-xl-10 col-lg-9">
                <div className='RecentPostTop'>
                  <h2>
                    Do you need Professionals to project and build your dream home?
                  </h2>
                  <p>
                    We offer the best engineers and builders to make your dreams come true.
                  </p>
                </div>
              </div>

              <div className="col-xl-2 col-lg-3">
                <div className='RecentPostTop'>
                  <button>
                    GET A QUOTE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid ">
          <div className="container-lg  FeatureMain">
            <div className='ServicesMainContent mt-3 mb-lg-4'>
              <h2>
                Recent Posts
              </h2>
              <p>
                Our team love to write about productivity and overcoming challenges. Check out our blog to know more about what we share.
              </p>
            </div>

            <div className="row mt-3 g-4 ">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className=''>
                  <img className='w-100' src={require("../Img/RecentPost1.png")} alt="icloudsoft_logo" />
                  <div className='RecentPost-Content'>
                    <h2>
                      Make Running a Part of Your Life
                    </h2>
                    <p>
                      Running improves your cardiovascular strength, lowers bad cholesterol and speeds up your metabolism. Not to mention the immense positive feelings...
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className=''>
                  <img className='w-100' src={require("../Img/RecentPost2.png")} alt="icloudsoft_logo" />
                  <div className='RecentPost-Content'>
                    <h2>
                      Take Breaks to Improve Your Productivity
                    </h2>
                    <p>
                      Every self-help program talks about the importance of taking a 10-15 minute break to boost your productivity. Breaks give us much...
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className=''>
                  <img className='w-100' src={require("../Img/RecentPost3.png")} alt="icloudsoft_logo" />
                  <div className='RecentPost-Content'>
                    <h2>
                      Start Writing a Journal
                    </h2>
                    <p>
                      An ideal time to write, comfortable digs, a great pen, and endless sheets of fabulous paper can make journaling more...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid " style={{ backgroundColor: "#f9f9f9" }}>
          <div className="container-lg  FeatureMain">
            <div className='ServicesMainContent mt-3'>
              <h2>
                What Our Clients Says
              </h2>
              <p>
                At EpicenterConsultants our goal is to achieve customer dreams effectively and efficiently. Check out what our customers have to say about us.
              </p>
            </div>

            <div className="row mt-3 g-4 ">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className='ClientSays'>
                  <div className='ClientSays-TestimonialSection'>
                    <p>
                      The team have been very friendly and helpful thus far with our project. It has been a smooth and efficient experience so far from initial contact to submitting the planning application.
                    </p>
                  </div>
                  <img className='ClientSaysImge' src={require("../Img/Client1.png")} alt="icloudsoft_logo" />
                  <h3>
                    Sai constructions
                  </h3>
                  <h6>
                    Commercial Building
                  </h6>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className='ClientSays'>
                  <div className='ClientSays-TestimonialSection'>
                    <p>
                      Thank you for beautiful plan, fantastic customer service. It has been a pleasure to work with you and your business. Very impressive!
                    </p>
                  </div>
                  <img className='ClientSaysImge' src={require("../Img/Client2.png")} alt="icloudsoft_logo" />
                  <h3>
                    Shrushti Hotels and Resort
                  </h3>
                  <h6>
                    Resort
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid ">
          <div className="container-lg  FeatureMain">
            <div className='ServicesMainContent mt-3 mb-lg-4'>
              <h2>
                Clients and Partners
              </h2>
              <p>
                EpicenterConsultants team has experience of working with many top companies from across the world. Few of our top clients are listed below.
              </p>
            </div>

            <div className="row text-center p-4 d-flex justify-content-center align-items-center row-cols-xl-6 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mt-3 g-2 ">
              <div>
                <img className='' src={require("../Img/Patners1.png")} alt="icloudsoft_logo" />
              </div>

              <div>
                <img className='' src={require("../Img/Patners2.png")} alt="icloudsoft_logo" />
              </div>

              <div>
                <img className='' src={require("../Img/Patners3.png")} alt="icloudsoft_logo" />
              </div>

              <div>
                <img className='' src={require("../Img/Patners4.png")} alt="icloudsoft_logo" />
              </div>

              <div>
                <img className='' src={require("../Img/Patners5.png")} alt="icloudsoft_logo" />
              </div>

              <div>
                <img className='' src={require("../Img/Patners6.png")} alt="icloudsoft_logo" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid" style={{ backgroundColor: "#FFD800" }}>
          <div className="container-lg FeatureMain">
            <div className="row d-flex justify-content-center align-items-center MainText">
              <div className="col-xl-10 col-lg-9">
                <div className='RecentPostTop'>
                  <h2>
                    Get in Touch
                  </h2>
                  <p>
                    Small steps lead to big innovations
                  </p>
                </div>
              </div>

              <div className="col-xl-2 col-lg-3">
                <div className='RecentPostTop'>
                  <button>
                    GET A QUOTE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whatsApp">
        <a href="https://wa.me/+919860843466?text=">
          <i class="fa-brands fa-whatsapp" ></i>
        </a>
      </section>


    </>
  )
}

export default Home