import React from 'react'
import './AboutUs.css'
import ColorSwittcher from './ColorSwittcher'

const AboutUs = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/ServicesBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "180px" }}>
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className='ServicesMainFirst'>
                                <h2>
                                    About Us
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ColorSwittcher />

            <section>
                <div className="container-fluid " style={{ backgroundColor: "#f9f9f9" }}>
                    <div className="container-lg  FeatureMain">
                        <div className="row d-flex justify-content-between mt-3 g-4 ">
                            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6">
                                <div className='AboutUsFirstContent'>
                                    <p>
                                        <span>Epicenter Consultants</span> Was Established 
                                         To Offer Professional Multi-Discipline Consulting Services For The
                                        Design And Construction Of Civil Engineering Projects.
                                    </p>
                                    <p>
                                        We Are Constantly Developing Our Boundaries To Meet The High Expectations Of Modern Customers.
                                    </p>
                                    <p>
                                        We Are Engaged To Provide In Structural Engineering Service For The Sectors Like; Residential,
                                        Commercial, Institutional, Industrial, Hospitality, Warehouse, Process Towers Etc…..
                                    </p>
                                    <p>
                                        Principal Objective Of Epicenter Consultants Is To Provide All Consultancies Under One Roof Required
                                        For Any The Civil Engineering Projects.
                                    </p>
                                    <p>
                                        We Provide Innovative And Cost Effective Most Appropriate Solutions For The Civil Engineering Projects
                                        To Meet The Client's Specialized Demands.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6">
                                <div className='mt-1'>
                                    <iframe className='w-100 m-0' title="gmap" style={{ height: "220px" }} src="https://www.youtube.com/embed/rENyyRwxpHo?feature=oembed">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className="container-fluid " style={{ backgroundColor: "#1a1a1a" }} >
                    <div className="container-lg  FeatureMain">
                        <div className='AboutUsFirstTeamContent mt-3 mb-lg-4'>
                            <h2>
                                Our Team
                            </h2>
                            <p>
                                Epicenter Consultants team has experts related to every area of construction and that enables us to provides complete range of services for any size of project.
                            </p>
                        </div>

                        <div className="row mt-3 g-3 ">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/OurTeam1.png")} alt="icloudsoft_logo" />
                                    <div className='AboutUsFirstOurTeam'>
                                        <h2>
                                            Tom Gene
                                        </h2>
                                        <h6>
                                            Mechanical Engineer
                                        </h6>
                                        <div className='text-center  text-white mt-4 mb-4' style={{ fontSize: '10px' }} >
                                            <span className='SocialIconAboutUs  text-center '>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                                                <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                                            </span>
                                        </div>
                                        <p>
                                            Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/OurTeam2.png")} alt="icloudsoft_logo" />
                                    <div className='AboutUsFirstOurTeam'>
                                        <h2>
                                            Rose Lewis
                                        </h2>
                                        <h6>
                                            Project Coordinator
                                        </h6>
                                        <div className='text-center  text-white mt-4 mb-4' style={{ fontSize: '10px' }} >
                                            <span className='SocialIconAboutUs  text-center '>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                                                <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                                            </span>
                                        </div>
                                        <p>
                                            Objectively innovate empowered manufactured products whereas parallel platforms. Holistically predominate extensible testing procedures for reliable supply chains. Dramatically engage top-line web services vis-a-vis cutting-edge deliverables.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className=''>
                                    <img className='w-100' src={require("../Img/OurTeam3.png")} alt="icloudsoft_logo" />
                                    <div className='AboutUsFirstOurTeam'>
                                        <h2>
                                            Steven Martin
                                        </h2>
                                        <h6>
                                            Electrical Engineer
                                        </h6>
                                        <div className='text-center  text-white mt-4 mb-4' style={{ fontSize: '10px' }} >
                                            <span className='SocialIconAboutUs  text-center '>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                                                <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                                            </span>
                                        </div>
                                        <p>
                                            Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. Dynamically innovate resource-leveling customer service for state of the art customer service.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className="container-fluid">
                    <div className="container-lg FeatureMain">
                        <div className='ServicesMainContent mb-lg-4'>
                            <h2>
                                Our Core Values
                            </h2>
                            <p>
                                Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward is important.
                            </p>
                        </div>
                        <div className="row mt-3 g-4">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className=''>
                                    <img className='' src={require("../Img/HomeSecondSection2.png")} alt="icloudsoft_logo" />
                                </div>
                                <div className='Feature-Content-AboutUs'>
                                    <h2>
                                        Offering Realistic Solutions
                                    </h2>
                                    <p>
                                        Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className=''>
                                    <img className='' src={require("../Img/HomeSecondSection3.png")} alt="icloudsoft_logo" />
                                </div>
                                <div className='Feature-Content-AboutUs'>
                                    <h2>
                                        Total Quality Control
                                    </h2>
                                    <p>
                                        Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions without functional solutions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className=''>
                                    <img className='' src={require("../Img/HomeSecondSection1.png")} alt="icloudsoft_logo" />
                                </div>
                                <div className='Feature-Content-AboutUs'>
                                    <h2>
                                        Understanding Requirements
                                    </h2>
                                    <p>
                                        Proactively envisioned multimedia based expertise and cross-media growth strategies. Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing. Holistically pontificate installed base portals after maintainable products.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFD800" }}>
                    <div className="container-lg FeatureMain">
                        <div className="row d-flex justify-content-center align-items-center MainText">
                            <div className="col-xl-10 col-lg-9">
                                <div className='RecentPostTop'>
                                    <h2>
                                        Get in Touch
                                    </h2>
                                    <p>
                                        Small steps lead to big innovations
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-3">
                                <div className='RecentPostTop'>
                                    <button>
                                        GET A QUOTE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutUs