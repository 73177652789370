import React from 'react'
import "./Projects.css"
import ColorSwittcher from './ColorSwittcher';

const Projects = () => {

    // const [display, setDisplay] = useState("ALL");

    // const [btn1, setBtn1] = useState("newStyle");

    // const [btn2, setBtn2] = useState("btnStyle");

    // const [btn3, setBtn3] = useState("btn3Style");

    // const [btn4, setBtn4] = useState("btn4Style");

    // const [btn5, setBtn5] = useState("btn5Style");

    // const [btn6, setBtn6] = useState("btn6Style");

    // const changleDisplay = () => {
    //     setDisplay("ALL")
    //     setBtn1("newStyle")
    //     setBtn2("btnStyle")
    // }

    // const changleDisplay1 = () => {
    //     setDisplay("OFFICE")
    //     setBtn1("btnStyle")
    //     setBtn2("newStyle")
    // }

    // const changleDisplay3 = () => {
    //     setDisplay("CLUB")
    //     setBtn3("btn3Style")
    //     setBtn4("btn4Style")
    // }

    // const changleDisplay4 = () => {
    //     setDisplay("RESIDENTIAL")
    //     setBtn3("btn4Style")
    //     setBtn4("btn3Style")
    // }

    // const changleDisplay5 = () => {
    //     setDisplay("SHOPPINGMALL")
    //     setBtn5("btn5Style")
    //     setBtn6("btn6Style")
    // }

    // const changleDisplay6 = () => {
    //     setDisplay("STRUCTURE")
    //     setBtn5("btn5Style")
    //     setBtn6("btn6Style")
    // }

    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/ServicesBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "180px" }}>
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className='ServicesMainFirst'>
                                <h2>
                                    What We Delivered
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ColorSwittcher />

            <section>
                <div className="container-fluid " >
                    <div className="FeatureMain">
                        <div className="container-md">
                            <div className="row g-4 p-lg-3 mb-lg-5 d-flex align-items-center">
                                <div className="col-xl-5 col-lg-5">
                                    <div className='OurServices'>
                                        <h2 className='ms-lg-3'>
                                            Our Projects
                                        </h2>
                                    </div>
                                </div>

                                <div className="col-xl-7 col-lg-7">
                                    <div className='OurServices'>
                                        <p>
                                            We are committed to delivering high-quality
                                            projects that present innovative design solutions
                                            and excellent value through careful control of
                                            cost and programme that ensure client satisfaction.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-md">
                            <div className="single-item">
                                <div className="row pt-1 g-4 ">
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className='w-100' src={require("../Img/RecentPostProject1.png")} alt="icloudsoft_logo" />
                                            <div className='RecentPostContentServices '>
                                                <h2>
                                                    Shah promoters & builders
                                                </h2>
                                                <p>
                                                    Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className='w-100' src={require("../Img/RecentPostProject2.png")} alt="icloudsoft_logo" />
                                            <div className='RecentPostContentServices'>
                                                <h2>
                                                    Sai constructions
                                                </h2>
                                                <p>
                                                    Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className='w-100' src={require("../Img/RecentPostProject3.png")} alt="icloudsoft_logo" />
                                            <div className='RecentPostContentServices'>
                                                <h2>
                                                    Design Interiors, Bungalow
                                                </h2>
                                                <p>
                                                    Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className='w-100' src={require("../Img/RecentPostProject4.png")} alt="icloudsoft_logo" />
                                            <div className='RecentPostContentServices'>
                                                <h2>
                                                    Shrushti Hotels and Resort
                                                </h2>
                                                <p>
                                                    Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className='w-100' src={require("../Img/RecentPostProject5.png")} alt="icloudsoft_logo" />
                                            <div className='RecentPostContentServices'>
                                                <h2>
                                                    Sprint consys Pvt. Ltd
                                                </h2>
                                                <p>
                                                    Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className='w-100' src={require("../Img/RecentPostProject6.png")} alt="icloudsoft_logo" />
                                            <div className='RecentPostContentServices'>
                                                <h2>
                                                    Pensive Architecture Company, Bunglow
                                                </h2>
                                                <p>
                                                    Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className='w-100' src={require("../Img/RecentPostProject7.png")} alt="icloudsoft_logo" />
                                            <div className='RecentPostContentServices'>
                                                <h2>
                                                    Shopping Mall
                                                </h2>
                                                <p>
                                                    Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className='w-100' src={require("../Img/RecentPostProject8.png")} alt="icloudsoft_logo" />
                                            <div className='RecentPostContentServices'>
                                                <h2>
                                                    Three Story House
                                                </h2>
                                                <p>
                                                    Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div className=''>
                                            <img className='w-100' src={require("../Img/RecentPostProject9.png")} alt="icloudsoft_logo" />
                                            <div className='RecentPostContentServices'>
                                                <h2>
                                                    Oval Building
                                                </h2>
                                                <p>
                                                    Pune
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="container-md">
                            <div className="row g-2 pb-lg-2 p-4 d-flex align-items-center "  >
                                <div className="col-lg-1 col-md-2 col-sm-2 col-3  d-flex justify-content-center lato-black"  >
                                    <button className={btn1} style={{ fontWeight: "500", fontSize: "13px", backgroundColor: "transparent", color: '#808080' }} onClick={changleDisplay}>ALL</button>
                                </div>

                                <div className="col-lg-1 col-md-2 col-sm-2  col-3 d-flex justify-content-center lato-black"  >
                                    <button className={btn3} style={{ fontWeight: "500", fontSize: "13px", backgroundColor: "transparent", color: '#808080' }} onClick={changleDisplay3}>CLUB</button>
                                </div>

                                <div className="col-lg-1 col-md-2 col-sm-2  col-3  d-flex justify-content-center lato-black"  >
                                    <button className={btn2} style={{ fontWeight: "500", fontSize: "13px", backgroundColor: "transparent", color: '#808080' }} onClick={changleDisplay1}>OFFICE</button>
                                </div>

                                <div className="col-lg-1 col-md-2 col-sm-2  col-3  d-flex justify-content-center lato-black"  >
                                    <button className={btn4} style={{ fontWeight: "500", fontSize: "13px", backgroundColor: "transparent", color: '#808080' }} onClick={changleDisplay4}>RESIDENTIAL</button>
                                </div>

                                <div className="col-lg-2  col-md-3 col-sm-3  col-6  d-flex justify-content-center lato-black"  >
                                    <button className={btn5} style={{ fontWeight: "500", fontSize: "13px", backgroundColor: "transparent", color: '#808080' }} onClick={changleDisplay5}>SHOPPING MALL</button>
                                </div>

                                <div className="col-lg-1 col-md-2 col-sm-2  col-6  d-flex justify-content-center lato-black"  >
                                    <button className={btn6} style={{ fontWeight: "500", fontSize: "13px", backgroundColor: "transparent", color: '#808080' }} onClick={changleDisplay6}>STRUCTURE</button>
                                </div>
                            </div>
                        </div>

                        <div>
                            {

                                display === "ALL" ?
                                    <div className="container-md">
                                        <div className="single-item">
                                            <div className="row pt-1 g-4 ">
                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className=''>
                                                        <img className='w-100' src={require("../Img/RecentPostProject1.png")} alt="icloudsoft_logo" />
                                                        <div className='RecentPostContentServices '>
                                                            <h2>
                                                                America’s Cup Building
                                                            </h2>
                                                            <p>
                                                                Valencia
                                                            </p>
                                                            <p>
                                                                / Structure
                                                            </p>
                                                            <p>
                                                                The America’s Cup Building, also known locally as Veles e Vents, is located in Valencia, Spain. The building was designed by the British architect David Chipperfield and inaugurated in 2006.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className=''>
                                                        <img className='w-100' src={require("../Img/RecentPostProject2.png")} alt="icloudsoft_logo" />
                                                        <div className='RecentPostContentServices'>
                                                            <h2>
                                                                Modern Villa
                                                            </h2>
                                                            <p>
                                                                Florida
                                                            </p>
                                                            <p>
                                                                / Residential
                                                            </p>
                                                            <p>
                                                                Etiam porta sem malesuada magna mollis euismod. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className=''>
                                                        <img className='w-100' src={require("../Img/RecentPostProject3.png")} alt="icloudsoft_logo" />
                                                        <div className='RecentPostContentServices'>
                                                            <h2>
                                                                Ultra-Modern Office
                                                            </h2>
                                                            <p>
                                                                New York
                                                            </p>
                                                            <p>
                                                                / Office
                                                            </p>
                                                            <p>
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className=''>
                                                        <img className='w-100' src={require("../Img/RecentPostProject4.png")} alt="icloudsoft_logo" />
                                                        <div className='RecentPostContentServices'>
                                                            <h2>
                                                                Modern Office Building
                                                            </h2>
                                                            <p>
                                                                San Francisco
                                                            </p>
                                                            <p>
                                                                / Office
                                                            </p>
                                                            <p>
                                                                Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nullam quis risus eget urna mollis ornare vel eu leo. Donec id elit non mi porta gravida at eget metus. Cras mattis consectetur purus sit amet fermentum
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className=''>
                                                        <img className='w-100' src={require("../Img/RecentPostProject5.png")} alt="icloudsoft_logo" />
                                                        <div className='RecentPostContentServices'>
                                                            <h2>
                                                                Club House
                                                            </h2>
                                                            <p>
                                                                California
                                                            </p>
                                                            <p>
                                                                / Club
                                                            </p>
                                                            <p>
                                                                Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className=''>
                                                        <img className='w-100' src={require("../Img/RecentPostProject6.png")} alt="icloudsoft_logo" />
                                                        <div className='RecentPostContentServices'>
                                                            <h2>
                                                                Classic Villa

                                                            </h2>
                                                            <p>
                                                                California
                                                            </p>
                                                            <p>
                                                                / Residential
                                                            </p>
                                                            <p>
                                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur blandit tempus porttitor. Maecenas faucibus mollis interdum. Cras mattis consectetur purus sit amet fermentum.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className=''>
                                                        <img className='w-100' src={require("../Img/RecentPostProject7.png")} alt="icloudsoft_logo" />
                                                        <div className='RecentPostContentServices'>
                                                            <h2>
                                                                Shopping Mall
                                                            </h2>
                                                            <p>
                                                                Chicago
                                                            </p>
                                                            <p>
                                                                / Shopping Mall
                                                            </p>
                                                            <p>
                                                                Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Duis mollis, est non commodo luctus.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className=''>
                                                        <img className='w-100' src={require("../Img/RecentPostProject8.png")} alt="icloudsoft_logo" />
                                                        <div className='RecentPostContentServices'>
                                                            <h2>
                                                                Three Story House
                                                            </h2>
                                                            <p>
                                                                Florida
                                                            </p>
                                                            <p>
                                                                / Residential
                                                            </p>
                                                            <p>
                                                                Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Duis mollis, est non commodo luctus.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                    <div className=''>
                                                        <img className='w-100' src={require("../Img/RecentPostProject9.png")} alt="icloudsoft_logo" />
                                                        <div className='RecentPostContentServices'>
                                                            <h2>
                                                                Oval Building
                                                            </h2>
                                                            <p>
                                                                Europe
                                                            </p>
                                                            <p>
                                                                / Structure
                                                            </p>
                                                            <p>
                                                                Oval shaped office building with glassy outlook. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    : display === "CLUB" ?
                                        <div className="container-md ">
                                            <div className="mt-2">
                                                <div className="single-item">
                                                    <div className="row g-4 d-flex" >
                                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                            <div className=''>
                                                                <img className='w-100' src={require("../Img/RecentPostProject5.png")} alt="icloudsoft_logo" />
                                                                <div className='RecentPostContentServices'>
                                                                    <h2>
                                                                        Club House
                                                                    </h2>
                                                                    <p>
                                                                        California
                                                                    </p>
                                                                    <p>
                                                                        / Club
                                                                    </p>
                                                                    <p>
                                                                        Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        : display === "OFFICE" ?
                                            <div className="container-md ">
                                                <div className="mt-2">
                                                    <div className="single-item">
                                                        <div className="row g-4 d-flex mb-2" >
                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                                <div className=''>
                                                                    <img className='w-100' src={require("../Img/RecentPostProject3.png")} alt="icloudsoft_logo" />
                                                                    <div className='RecentPostContentServices'>
                                                                        <h2>
                                                                            Ultra-Modern Office
                                                                        </h2>
                                                                        <p>
                                                                            New York
                                                                        </p>
                                                                        <p>
                                                                            / Office
                                                                        </p>
                                                                        <p>
                                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                                <div className=''>
                                                                    <img className='w-100' src={require("../Img/RecentPostProject4.png")} alt="icloudsoft_logo" />
                                                                    <div className='RecentPostContentServices'>
                                                                        <h2>
                                                                            Modern Office Building
                                                                        </h2>
                                                                        <p>
                                                                            San Francisco
                                                                        </p>
                                                                        <p>
                                                                            / Office
                                                                        </p>
                                                                        <p>
                                                                            Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Nullam quis risus eget urna mollis ornare vel eu leo. Donec id elit non mi porta gravida at eget metus. Cras mattis consectetur purus sit amet fermentum
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            : display === "RESIDENTIAL" ?
                                                <div className="container-md ">
                                                    <div className="mt-2">
                                                        <div className="single-item">
                                                            <div className="row g-4 d-flex mb-2" >
                                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                                    <div className=''>
                                                                        <img className='w-100' src={require("../Img/RecentPostProject2.png")} alt="icloudsoft_logo" />
                                                                        <div className='RecentPostContentServices'>
                                                                            <h2>
                                                                                Modern Villa
                                                                            </h2>
                                                                            <p>
                                                                                Florida
                                                                            </p>
                                                                            <p>
                                                                                / Residential
                                                                            </p>
                                                                            <p>
                                                                                Etiam porta sem malesuada magna mollis euismod. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                                    <div className=''>
                                                                        <img className='w-100' src={require("../Img/RecentPostProject6.png")} alt="icloudsoft_logo" />
                                                                        <div className='RecentPostContentServices'>
                                                                            <h2>
                                                                                Classic Villa

                                                                            </h2>
                                                                            <p>
                                                                                California
                                                                            </p>
                                                                            <p>
                                                                                / Residential
                                                                            </p>
                                                                            <p>
                                                                                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Curabitur blandit tempus porttitor. Maecenas faucibus mollis interdum. Cras mattis consectetur purus sit amet fermentum.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                                    <div className=''>
                                                                        <img className='w-100' src={require("../Img/RecentPostProject8.png")} alt="icloudsoft_logo" />
                                                                        <div className='RecentPostContentServices'>
                                                                            <h2>
                                                                                Three Story House
                                                                            </h2>
                                                                            <p>
                                                                                Florida
                                                                            </p>
                                                                            <p>
                                                                                / Residential
                                                                            </p>
                                                                            <p>
                                                                                Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Duis mollis, est non commodo luctus.
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                : display === "SHOPPINGMALL" ?
                                                    <div className="container-md ">
                                                        <div className="mt-2">
                                                            <div className="single-item">
                                                                <div className="row g-4 d-flex" >
                                                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                                        <div className=''>
                                                                            <img className='w-100' src={require("../Img/RecentPostProject7.png")} alt="icloudsoft_logo" />
                                                                            <div className='RecentPostContentServices'>
                                                                                <h2>
                                                                                    Shopping Mall
                                                                                </h2>
                                                                                <p>
                                                                                    Chicago
                                                                                </p>
                                                                                <p>
                                                                                    / Shopping Mall
                                                                                </p>
                                                                                <p>
                                                                                    Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Duis mollis, est non commodo luctus.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : display === "STRUCTURE" ?
                                                        <div className="container-md ">
                                                            <div className="mt-2">
                                                                <div className="single-item">
                                                                    <div className="row g-4 d-flex mb-2" >
                                                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                                            <div className=''>
                                                                                <img className='w-100' src={require("../Img/RecentPostProject1.png")} alt="icloudsoft_logo" />
                                                                                <div className='RecentPostContentServices '>
                                                                                    <h2>
                                                                                        America’s Cup Building
                                                                                    </h2>
                                                                                    <p>
                                                                                        Valencia
                                                                                    </p>
                                                                                    <p>
                                                                                        / Structure
                                                                                    </p>
                                                                                    <p>
                                                                                        The America’s Cup Building, also known locally as Veles e Vents, is located in Valencia, Spain. The building was designed by the British architect David Chipperfield and inaugurated in 2006.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                                                            <div className=''>
                                                                                <img className='w-100' src={require("../Img/RecentPostProject9.png")} alt="icloudsoft_logo" />
                                                                                <div className='RecentPostContentServices'>
                                                                                    <h2>
                                                                                        Oval Building
                                                                                    </h2>
                                                                                    <p>
                                                                                        Europe
                                                                                    </p>
                                                                                    <p>
                                                                                        / Structure
                                                                                    </p>
                                                                                    <p>
                                                                                        Oval shaped office building with glassy outlook. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        : <div>
                                                            somethings went wrong
                                                        </div>
                            }
                        </div> */}
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFD800" }}>
                    <div className="container-lg FeatureMain">
                        <div className="row d-flex justify-content-center align-items-center MainText">
                            <div className="col-xl-10 col-lg-9">
                                <div className='RecentPostTop'>
                                    <h2>
                                        Get in Touch
                                    </h2>
                                    <p>
                                        Leverage agile frameworks to provide a robust synopsis for high level overviews.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-3">
                                <div className='RecentPostTop'>
                                    <button>
                                        GET A QUOTE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Projects