import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import Navbar from './Navbar'
import Silder from './Silder'
import TernaryOperator from './TernaryOperator'
import Footer from './Footer'
import ColorSwittcher from './ColorSwittcher'
import Error from './Error'
import Services from './Services'
import Projects from './Projects'
import AboutUs from './AboutUs'
import Contact from './Contact'
import RccDesign from './RccDesign'
import StructuralSteelDesign from './StructuralSteelDesign'
import Restoration from './Restoration'
import ThirdPartyReviewValidation from './ThirdPartyReviewValidation'
import StructuralAudit from './StructuralAudit'
import StabilityCertificate from './StabilityCertificate'
import FeasibilityReport from './FeasibilityReport'
import Estimation from './Estimation'
import AsBuilt from './AsBuilt'

const Routing = () => {
    return (
        <>

            <React.Fragment>
                <header>
                    <Navbar />
                </header>
                
                <main>
                    <Routes>
                        <Route exact to path='/' element={<Home />} />
                        <Route exact to path='/services' element={<Services />} />

                        <Route exact to path='/rccdesign' element={<RccDesign />} />
                        <Route exact to path='/structuralsteeldesign' element={<StructuralSteelDesign />} />
                        <Route exact to path='/restoration' element={<Restoration />} />
                        <Route exact to path='/tprv' element={<ThirdPartyReviewValidation />} />
                        <Route exact to path='/structuralaudit' element={<StructuralAudit />} />
                        <Route exact to path='/scs' element={<StabilityCertificate />} />
                        <Route exact to path='/fr' element={<FeasibilityReport />} />
                        <Route exact to path='/estimation' element={<Estimation />} />
                        <Route exact to path='/abdp' element={<AsBuilt />} />

                        <Route exact to path='/projects' element={<Projects />} />
                        <Route exact to path='/aboutus' element={<AboutUs />} />
                        <Route exact to path='/contact' element={<Contact />} />

                        <Route exact to path='/silder' element={<Silder />} />
                        <Route exact to path='/ternaryoperator' element={<TernaryOperator />} />
                        <Route exact to path='/colorswittcher' element={<ColorSwittcher />} />

                        <Route exact to path='*' element={<Error />} />
                    </Routes>
                </main>
                <footer>
                    <Footer />
                </footer>
            </React.Fragment>

        </>
    )
}

export default Routing