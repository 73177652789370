import React from 'react'
import "./Services.css"
import ColorSwittcher from './ColorSwittcher'


const Services = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/ServicesBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "180px" }}>
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className='ServicesMainFirst'>
                                <h2>
                                    What We Do
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ColorSwittcher />

            {/* <section>
                <div className="container-fluid">
                    <div className="container-md FeatureMain">
                        <div className="row g-4 d-flex align-items-center">
                            <div className="col-xl-5 col-lg-5">
                                <div className='OurServices'>
                                    <h2>
                                        Our Services
                                    </h2>
                                </div>
                            </div>

                            <div className="col-xl-7 col-lg-7">
                                <div className='OurServices'>
                                    <p>
                                        We are committed to delivering high-quality
                                        projects that present innovative design solutions
                                        and excellent value through careful control of
                                        cost and programme that ensure client satisfaction.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className="container-fluid " >
                    <div className="container-md  FeatureMain">
                        <div className="row g-4 d-flex align-items-center ">
                            <div className="col-xl-5 col-lg-5">
                                <div className='OurServices'>
                                    <h2>
                                        Our Services
                                    </h2>
                                </div>
                            </div>

                            <div className="col-xl-7 col-lg-7">
                                <div className='OurServices'>
                                    <p>
                                        We Are Providing A Seamless Range Of Services For Our Clients To Fulfill Their Specific Requirement With The Help Of Advanced Technologies.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3 mt-4 g-4">
                            <div className='text-start mb-3 mt-5 '>
                                <h2 className='merriweather-bold'>
                                    Structural Design Services
                                </h2>
                                <p className='laila-semibold' style={{ fontWeight: "400", fontSize: "14px" }}>
                                    Structural Design Service is an essential service in the construction industry. Our team of experienced structural engineers works closely with architects, contractors, and builders/clients to ensure that every project is designed to withstand the toughest of conditions.
                                </p>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <a href="/rccdesign" className='text-decoration-none'>
                                    <div className='AboutService'>
                                        <div className='d-flex justify-content-center'>
                                            <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                <img className='' src={require("../Img/RccDesign.png")} alt="" />
                                            </div>
                                        </div>
                                        <h2>
                                            RCC Design
                                        </h2>
                                        <p>
                                            The design processes in which we sketch, build physical models and create computer visualisations in order to…
                                        </p>
                                        <div className='OurServices'>
                                            <button>
                                                Learn More
                                            </button>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <a href="/structuralsteeldesign" className='text-decoration-none'>
                                        <div className='AboutService'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/StruSteelDesign.png")} alt="" />
                                                </div>
                                            </div>
                                            <h2>
                                                Structural Steel Design
                                            </h2>
                                            <p>
                                                We provide full project management solution to help you get your dream project completed within your budget…
                                            </p>
                                            <div className='OurServices'>
                                                <button>
                                                    Learn More
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <a href="/restoration" className='text-decoration-none'>
                                        <div className='AboutService'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/Restoration.png")} alt="" />
                                                </div>
                                            </div>
                                            <h2>
                                                Restoration / Rehabilitation / Strengthening Works
                                            </h2>
                                            <p>
                                                Interior design is the art and science of enhancing the interiors, sometimes including the exterior, of a…
                                            </p>
                                            <div className='OurServices'>
                                                <button>
                                                    Learn More
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                            </div>

                            <div className='text-start mt-5 mb-3 merriweather-bold'>
                                <h2>
                                    Value Engineering
                                </h2>
                                {/* <p>
                                    Structural Design Service is an essential service in the construction industry. Our team of experienced structural engineers works closely with architects, contractors, and builders/clients to ensure that every project is designed to withstand the toughest of conditions.
                                </p> */}
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <a href="/tprv" className='text-decoration-none'>
                                        <div className='AboutService'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/ThirdParty.png")} alt="" />
                                                </div>
                                            </div>
                                            <h2>
                                                Third Party Review Validation
                                            </h2>
                                            <p>
                                                Interior design is the art and science of enhancing the interiors, sometimes including the exterior, of a…
                                            </p>
                                            <div className='OurServices'>
                                                <button>
                                                    Learn More
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <a href="/structuralaudit" className='text-decoration-none'>
                                        <div className='AboutService'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/StructuralAudit.png")} alt="" />
                                                </div>
                                            </div>
                                            <h2>
                                                Structural Audit
                                            </h2>
                                            <p>
                                                It is the 3D computer graphics process of automatically converting 3D wire frame models into 2D images…
                                            </p>
                                            <div className='OurServices'>
                                                <button>
                                                    Learn More
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <a href="/scs" className='text-decoration-none'>
                                        <div className='AboutService'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/StabilityCertificateService.png")} alt="" />
                                                </div>
                                            </div>
                                            <h2>
                                                Stability Certificate Service
                                            </h2>
                                            <p>
                                                Landscaping is an activity that improves the visible features of an area of land by art and…
                                            </p>
                                            <div className='OurServices'>
                                                <button>
                                                    Learn More
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <a href="/fr" className='text-decoration-none'>
                                        <div className='AboutService'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/FeasibilityReport.png")} alt="" />
                                                </div>
                                            </div>
                                            <h2>
                                                Feasibility Report
                                            </h2>
                                            <p>
                                                We provide building maintenance services after project completion. Lorem Ipsum is simply dummy text of the printing…
                                            </p>
                                            <div className='OurServices'>
                                                <button>
                                                    Learn More
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <a href="/estimation" className='text-decoration-none'>
                                        <div className='AboutService'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/Estimation.png")} alt="" />
                                                </div>
                                            </div>
                                            <h2>
                                                Estimation, Costing And Tendering
                                            </h2>
                                            <p>
                                                We provide building maintenance services after project completion. Lorem Ipsum is simply dummy text of the printing…
                                            </p>
                                            <div className='OurServices'>
                                                <button>
                                                    Learn More
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                            </div>

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                    <a href="/abdp" className='text-decoration-none'>
                                        <div className='AboutService'>
                                            <div className='d-flex justify-content-center'>
                                                <div className='CommonIconAboutIcon d-flex justify-content-center align-items-center'>
                                                    <img className='' src={require("../Img/built.png")} alt="" />
                                                </div>
                                            </div>
                                            <h2>
                                                As-Built Drawing Preparation
                                            </h2>
                                            <p>
                                                We provide building maintenance services after project completion. Lorem Ipsum is simply dummy text of the printing…
                                            </p>
                                            <div className='OurServices'>
                                                <button>
                                                    Learn More
                                                </button>
                                            </div>
                                        </div>
                                    </a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFD800" }}>
                    <div className="container-lg FeatureMain">
                        <div className="row d-flex justify-content-center align-items-center MainText">
                            <div className="col-xl-10 col-lg-9">
                                <div className='RecentPostTop'>
                                    <h2>
                                        Get in Touch
                                    </h2>
                                    <p>
                                        Leverage agile frameworks to provide a robust synopsis for high level overviews.
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-3">
                                <div className='RecentPostTop'>
                                    <button>
                                        GET A QUOTE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Services