import React, { useRef, useState } from 'react'
import "./Contact.css"
import { NavLink } from 'react-router-dom'

const Contact = () => {

    const form = useRef();

    const [name, setName] = useState("");
    const [subject, setsubject] = useState("");
    const [email, setEmail] = useState("");
    const [massage, setMassage] = useState("");
    const [errors, setErrors] = useState({});


    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.phone = ""; errors.email = ""; errors.massage = "";

            var url = "https://wa.me/1271379270?text="
                + " NAME : " + name + 
                " Subject : " + subject +
                " EMAIL : " + email +
                " MASSAGE : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;
    }

    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/ServicesBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "180px" }}>
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className='ServicesMainFirst'>
                                <h2>
                                    Contact
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" >
                    <div className="container FeatureMain  ">
                        <div className='merriweather-bold'>
                            <h2 className='' style={{ color: "#1a1a1a", fontWeight: "700", fontSize: "30px", marginBottom: "20px" }}>
                                Contact Us
                            </h2>
                            <p style={{ color: "#808080", fontWeight: "400", fontSize: "16px", marginBottom: "20px", lineHeight: "27px" }}>
                                For any pre-sale questions or general inquiries, Please use the contact form below.
                            </p>
                        </div>
                        <div className="row g-4 text-dark mb-lg-5 pb-5  pt-5">
                            <div className="col-lg-12">
                                <div className="row d-flex justify-content-between g-4 laila-semibold">
                                    <div className="col-lg-6">
                                        <div className=''>
                                            <form ref={form} onSubmit={loginHandle} className="row g-4" style={{ borderRadius: "0px 0px 0px 0px" }}>
                                                <div class="col-lg-12 ">
                                                    <label className='' style={{ fontSize: "12px", fontWeight: "400", marginBottom: "20px" }} htmlFor="">YOUR NAME (REQUIRED)</label>
                                                    <input type="text" className="form-control   text-dark UbuntuFONTFAMILY  " style={{ borderRadius: "0px 0px 0px 0px", borderTop: "none", borderRight: "none", borderLeft: "none", borderBottom: "2px solid #e5e5e5" }} id="inputName" name="name"
                                                        onChange={e => setName(e.target.value)} value={name}
                                                    />
                                                    {errors.name && <div className='text-danger'>{errors.name}</div>}
                                                </div>

                                                <div className='col-lg-12'>
                                                    <label className='' style={{ fontSize: "12px", fontWeight: "400", marginBottom: "20px" }} htmlFor="">YOUR EMAIL (REQUIRED)</label>
                                                    <input type="email" className="form-control boderRadius UbuntuFONTFAMILY" style={{ borderRadius: "0px 0px 0px 0px", borderTop: "none", borderRight: "none", borderLeft: "none", borderBottom: "2px solid #e5e5e5" }} id="inputEmail4" name="email"
                                                        onChange={e => setEmail(e.target.value)} value={email}
                                                    />
                                                    {errors.email && <div className='text-danger'>{errors.email}</div>}
                                                </div>

                                                <div class="col-lg-12 ">
                                                    <label className='' style={{ fontSize: "12px", fontWeight: "400", marginBottom: "20px" }} htmlFor="">SUBJECT</label>
                                                    <input type="text" className="form-control text-dark UbuntuFONTFAMILY" style={{ borderRadius: "0px 0px 0px 0px", borderTop: "none", borderRight: "none", borderLeft: "none", borderBottom: "2px solid #e5e5e5" }} id="inputPhone" name="phone"
                                                        onChange={e => setsubject(e.target.value)} value={subject}
                                                    />
                                                    {errors.subject && <div className='text-danger'>{errors.subject}</div>}
                                                </div>

                                                <div className="col-lg-12">
                                                    <label className='' style={{ fontSize: "12px", fontWeight: "400", marginBottom: "20px" }} htmlFor="">YOUR MESSAGE</label>
                                                    <textarea type="text" className="form-control   text-dark UbuntuFONTFAMILY" style={{ borderRadius: "0px 0px 0px 0px", borderTop: "none", borderRight: "none", borderLeft: "none", borderBottom: "2px solid #e5e5e5" }} id="inputText3" name="message"
                                                        onChange={e => setMassage(e.target.value)} value={massage}
                                                    />
                                                    {errors.massage && <div className='text-danger'>{errors.massage}</div>}
                                                </div>

                                                <div className="col-lg-12 mt-lg-4 pt-4">
                                                    <button type="submit" className=" SendButton "  ><b className='' >SUBMIT</b></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className='ms-lg-4'>
                                            <ul className='MainResponsiveFormDown' style={{ listStyle: "none" }}>
                                                <li className='MainResponsiveFormCopmanyContact' >
                                                    <div className='d-flex'>
                                                        <i class="fa-solid fa-phone me-3"></i>
                                                        <h4 className='' style={{ fontSize: "12px", color: "#808080" }}>
                                                            Phone
                                                        </h4>
                                                    </div>

                                                    <NavLink className="text-decoration-none " to='tel:9860843466'>
                                                        <h6 className='ms-4 ps-2 CommonHoverContact' >
                                                            9860 843 466.
                                                        </h6>
                                                    </NavLink>
                                                </li>

                                                <li className='MainResponsiveFormCopmanyContact' >
                                                    <div className='d-flex'>
                                                        <i class="fa-solid fa-fax me-3"></i>
                                                        <h4 className='' style={{ fontSize: "12px", color: "#808080" }}>
                                                            Whatsapp
                                                        </h4>
                                                    </div>

                                                    <NavLink className="text-decoration-none " to='https://wa.me/+919860843466?text='>
                                                        <h6 className='ms-4 ps-2 CommonHoverContact' >
                                                            9860 843 466
                                                        </h6>
                                                    </NavLink>
                                                </li>

                                                <li className='MainResponsiveFormCopmanyContact' >
                                                    <div className='d-flex'>
                                                        <i class="fa-sharp fa-solid fa-envelope me-3"></i>
                                                        <h4 className='' style={{ fontSize: "12px", color: "#808080" }}>
                                                            Email
                                                        </h4>
                                                    </div>

                                                    <NavLink className="text-decoration-none " to='mailto:info@easc.co.in.'>
                                                        <h6 className='ms-4 ps-2 CommonHoverContact' >
                                                            info@easc.co.in.
                                                        </h6>
                                                    </NavLink>
                                                </li>

                                                <li className='MainResponsiveFormCopmanyContact'>
                                                    <div className='d-flex'>
                                                        <i class="fa-solid fa-location-dot me-3"></i>
                                                        <h4 className='' style={{ fontSize: "12px", color: "#808080" }}>
                                                            Address
                                                        </h4>
                                                    </div>

                                                    <h6 className='ms-4 ps-2' style={{ fontSize: "15px", fontWeight: "500", color: "#1a1a1a", lineHeight: "24px" }}>
                                                        2nd Floor, Row House No. 3, Survey No. 18, Trimurti Chowk,
                                                        Behind Trimurti Heights, Bavdhan, Pune,
                                                        Maharashtra 411021.
                                                    </h6>

                                                </li>

                                                <li className='MainResponsiveFormCopmanyContact' >
                                                    <div className='d-flex mb-2'>
                                                        <i class="fa-solid fa-clock me-3"></i>
                                                        <h4 className='' style={{ fontSize: "12px", color: "#808080" }}>
                                                            Opening Days/Hours
                                                        </h4>
                                                    </div>

                                                    <h6 className='ms-4 ps-2 ' style={{ fontSize: "15px", fontWeight: "500", color: "#1a1a1a", lineHeight: "17px" }}>
                                                        <span>
                                                            Monday-Friday
                                                        </span>

                                                        <span className='ms-5'>
                                                            10:00 AM - 6:00 PM
                                                        </span>
                                                    </h6>

                                                    <h6 className='ms-4 ps-2' style={{ fontSize: "15px", fontWeight: "500", color: "#1a1a1a", lineHeight: "17px" }}>
                                                        <span>
                                                            Saturday
                                                        </span>

                                                        <span className='ms-5'>
                                                            10:00 AM - 2:00 PM
                                                        </span>
                                                    </h6>

                                                    <h6 className='ms-4 ps-2' style={{ fontSize: "15px", fontWeight: "500", color: "#1a1a1a", lineHeight: "17px" }}>
                                                        <span>
                                                            Sunday
                                                        </span>

                                                        <span className='ms-5'>
                                                            Closed
                                                        </span>
                                                    </h6>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container-fluid p-0 ">
                <div className='' style={{ lineHeight: "0px" }}>
                    <iframe className='w-100 m-0' title="gmap" style={{ height: "550px" }} src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3783.393402872083!2d73.77798067465191!3d18.511116169464106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDMwJzQwLjAiTiA3M8KwNDYnNTAuMCJF!5e0!3m2!1sen!2sin!4v1718607144823!5m2!1sen!2sin">
                    </iframe>
                </div>
            </div>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#FFD800" }}>
                    <div className="container-lg FeatureMain">
                        <div className="row d-flex justify-content-center align-items-center MainText">
                            <div className="col-xl-10 col-lg-9">
                                <div className='RecentPostTop'>
                                    <h2>
                                        Get in Touch
                                    </h2>
                                    <p>
                                        Small steps lead to big innovations
                                    </p>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-3">
                                <div className='RecentPostTop'>
                                    <button>
                                        GET A QUOTE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contact