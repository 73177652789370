import React from 'react'
import "./TernaryOpertor.css"
import { NavLink } from 'react-router-dom';

const TernaryOperator = () => {

    

    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#1a1a1a" }}>
                    <div className="p-lg-5 lato-black">
                        <div className=" pt-5 ">
                            <div className='RecentProjectMainContent'>
                                <h2>
                                    Recent Projects
                                </h2>
                                <p>
                                    EpicenterConsultants team has worked over many amazing projects and you can check out few recent ones given below.
                                </p>
                            </div>

                            <div className="container-fluid ">
                                <div className=" mt-2 p-2 d-none d-sm-block">
                                    <div className="testimonials w-100">
                                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <div className="single-item">
                                                        <div className="row g-4 d-flex justify-content-center" >
                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                <div className="image HoverEffect ">
                                                                    <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                    <div className='contentAll '>
                                                                        <h1> Shah promoters & builders </h1>
                                                                        <p>
                                                                            Pune
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                <div className="image HoverEffect ">
                                                                    <img className='' src={require("../Img/ContentAll2.png")} alt="" />
                                                                    <div className='contentAll '>
                                                                        <h1> Sai constructions </h1>
                                                                        <p>
                                                                            Pune
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                <div className="image HoverEffect ">
                                                                    <img className='' src={require("../Img/ContentAll3.png")} alt="" />
                                                                    <div className='contentAll '>
                                                                        <h1> Design Interiors, Bungalow </h1>
                                                                        <p>
                                                                            Pune
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                <div className="image HoverEffect ">
                                                                    <img className='' src={require("../Img/ContentAll4.png")} alt="" />
                                                                    <div className='contentAll '>
                                                                        <h1> Shrushti Hotels and Resort </h1>
                                                                        <p>
                                                                            Pune
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                <div className="image HoverEffect ">
                                                                    <img className='' src={require("../Img/ContentAll5.png")} alt="" />
                                                                    <div className='contentAll '>
                                                                        <h1> Sprint consys Pvt. Ltd </h1>
                                                                        <p>
                                                                            Pune
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                <div className="image HoverEffect ">
                                                                    <img className='' src={require("../Img/ContentAll6.png")} alt="" />
                                                                    <div className='contentAll '>
                                                                        <h1> Pensive Architecture
                                                                            <br />
                                                                            Company, Bunglow </h1>
                                                                        <p>
                                                                            Pune
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="conatiner-fluid FONT mb-5 pb-5 d-sm-none d-block " >
                                    <div className=" pt-5 mt-2">
                                        <div className="testimonials ">
                                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <div className="single-item">
                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                <div className="col-12">
                                                                    <div className="image position-relative">
                                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                            <NavLink to='' className='btnResp'>
                                                                                VIEW PROJECT
                                                                                <span className='ms-3'>
                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                </span>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className='CompanyName'>
                                                                            <h3 className='mt-3'>
                                                                                Ultra-Modern Office
                                                                            </h3>
                                                                            <p className='pt-1'>
                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                    New York
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="carousel-item">
                                                        <div className="single-item">
                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                <div className="col-12">
                                                                    <div className="image position-relative">
                                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                            <NavLink to='' className='btnResp'>
                                                                                VIEW PROJECT
                                                                                <span className='ms-3'>
                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                </span>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className='CompanyName'>
                                                                            <h3 className='mt-3'>
                                                                                Ultra-Modern Office
                                                                            </h3>
                                                                            <p className='pt-1'>
                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                    New York
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="carousel-item">
                                                        <div className="single-item">
                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                <div className="col-12">
                                                                    <div className="image position-relative">
                                                                        <img className='' src={require("../Img/MidSilder3.png")} alt="" />
                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                            <NavLink to='' className='btnResp'>
                                                                                VIEW PROJECT
                                                                                <span className='ms-3'>
                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                </span>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className='CompanyName'>
                                                                            <h3 className='mt-3'>
                                                                                Ultra-Modern Office
                                                                            </h3>
                                                                            <p className='pt-1'>
                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                    New York
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="carousel-item">
                                                        <div className="single-item">
                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                <div className="col-12">
                                                                    <div className="image position-relative">
                                                                        <img className='' src={require("../Img/MidSilder4.png")} alt="" />
                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                            <NavLink to='' className='btnResp'>
                                                                                VIEW PROJECT
                                                                                <span className='ms-3'>
                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                </span>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className='CompanyName'>
                                                                            <h3 className='mt-3'>
                                                                                Ultra-Modern Office
                                                                            </h3>
                                                                            <p className='pt-1'>
                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                    New York
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="carousel-item">
                                                        <div className="single-item">
                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                <div className="col-12">
                                                                    <div className="image position-relative">
                                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                            <NavLink to='' className='btnResp'>
                                                                                VIEW PROJECT
                                                                                <span className='ms-3'>
                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                </span>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className='CompanyName'>
                                                                            <h3 className='mt-3'>
                                                                                Ultra-Modern Office
                                                                            </h3>
                                                                            <p className='pt-1'>
                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                    New York
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="carousel-item">
                                                        <div className="single-item">
                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                <div className="col-12">
                                                                    <div className="image position-relative">
                                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                            <NavLink to='' className='btnResp'>
                                                                                VIEW PROJECT
                                                                                <span className='ms-3'>
                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                </span>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className='CompanyName'>
                                                                            <h3 className='mt-3'>
                                                                                Ultra-Modern Office
                                                                            </h3>
                                                                            <p className='pt-1'>
                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                    New York
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div>
                            </div>

                            {/* <div className="row p-lg-4 d-flex justify-content-center align-items-center ">
                                <div className="row g-2 pb-lg-2 d-flex justify-content-center align-items-center "  >
                                    <div className="col-lg-1 col-md-2 col-sm-2 col-3  d-flex justify-content-center lato-black"  >
                                        <button className={btn1} style={{ fontWeight: "600", fontSize: "13px" }} onClick={changleDisplay}>ALL</button>
                                    </div>

                                    <div className="col-lg-1 col-md-2 col-sm-2  col-3 d-flex justify-content-center lato-black"  >
                                        <button className={btn3} style={{ fontWeight: "600", fontSize: "13px" }} onClick={changleDisplay3}>CLUB</button>
                                    </div>

                                    <div className="col-lg-1 col-md-2 col-sm-2  col-3  d-flex justify-content-center lato-black"  >
                                        <button className={btn2} style={{ fontWeight: "600", fontSize: "13px" }} onClick={changleDisplay1}>OFFICE</button>
                                    </div>

                                    <div className="col-lg-1 col-md-2 col-sm-2  col-3  d-flex justify-content-center lato-black"  >
                                        <button className={btn4} style={{ fontWeight: "600", fontSize: "13px" }} onClick={changleDisplay4}>RESIDENTIAL</button>
                                    </div>

                                    <div className="col-lg-2  col-md-3 col-sm-3  col-4  d-flex justify-content-center lato-black"  >
                                        <button className={btn5} style={{ fontWeight: "600", fontSize: "13px" }} onClick={changleDisplay5}>SHOPPING MALL</button>
                                    </div>

                                    <div className="col-lg-1 col-md-2 col-sm-2  col-3  d-flex justify-content-center lato-black"  >
                                        <button className={btn6} style={{ fontWeight: "600", fontSize: "13px" }} onClick={changleDisplay6}>STRUCTURE</button>
                                    </div>
                                </div>

                                <div >
                                    {

                                        display === "ALL" ?
                                            <div className="container-fluid ">
                                                <div className=" mt-2 p-2 d-none d-sm-block">
                                                    <div className="testimonials w-100">
                                                        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                                            <div className="carousel-inner">
                                                                <div className="carousel-item active">
                                                                    <div className="single-item">
                                                                        <div className="row g-4 d-flex justify-content-center" >
                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Shah promoters & builders </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll2.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Sai constructions </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll3.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Design Interiors, Bungalow </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll4.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Shrushti Hotels and Resort </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll5.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Sprint consys Pvt. Ltd </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll6.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Pensive Architecture
                                                                                            <br />
                                                                                             Company, Bunglow </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="carousel-item">
                                                                    <div className="single-item">
                                                                        <div className="row g-4 d-flex " >
                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Shah promoters & builders </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll2.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Sai constructions </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll3.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Design Interiors, Bungalow </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll4.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Shrushti Hotels and Resort </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 ">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll5.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> Sprint consys Pvt. Ltd </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="conatiner-fluid FONT mb-5 pb-5 d-sm-none d-block " >
                                                    <div className=" pt-5 mt-2">
                                                        <div className="testimonials ">
                                                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                                                <div className="carousel-inner">
                                                                    <div className="carousel-item active">
                                                                        <div className="single-item">
                                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                                <div className="col-12">
                                                                                    <div className="image position-relative">
                                                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                            <NavLink to='' className='btnResp'>
                                                                                                VIEW PROJECT
                                                                                                <span className='ms-3'>
                                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                                </span>
                                                                                            </NavLink>
                                                                                        </div>
                                                                                        <div className='CompanyName'>
                                                                                            <h3 className='mt-3'>
                                                                                                Ultra-Modern Office
                                                                                            </h3>
                                                                                            <p className='pt-1'>
                                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                    New York
                                                                                                </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="carousel-item">
                                                                        <div className="single-item">
                                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                                <div className="col-12">
                                                                                    <div className="image position-relative">
                                                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                            <NavLink to='' className='btnResp'>
                                                                                                VIEW PROJECT
                                                                                                <span className='ms-3'>
                                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                                </span>
                                                                                            </NavLink>
                                                                                        </div>
                                                                                        <div className='CompanyName'>
                                                                                            <h3 className='mt-3'>
                                                                                                Ultra-Modern Office
                                                                                            </h3>
                                                                                            <p className='pt-1'>
                                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                    New York
                                                                                                </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="carousel-item">
                                                                        <div className="single-item">
                                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                                <div className="col-12">
                                                                                    <div className="image position-relative">
                                                                                        <img className='' src={require("../Img/MidSilder3.png")} alt="" />
                                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                            <NavLink to='' className='btnResp'>
                                                                                                VIEW PROJECT
                                                                                                <span className='ms-3'>
                                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                                </span>
                                                                                            </NavLink>
                                                                                        </div>
                                                                                        <div className='CompanyName'>
                                                                                            <h3 className='mt-3'>
                                                                                                Ultra-Modern Office
                                                                                            </h3>
                                                                                            <p className='pt-1'>
                                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                    New York
                                                                                                </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="carousel-item">
                                                                        <div className="single-item">
                                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                                <div className="col-12">
                                                                                    <div className="image position-relative">
                                                                                        <img className='' src={require("../Img/MidSilder4.png")} alt="" />
                                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                            <NavLink to='' className='btnResp'>
                                                                                                VIEW PROJECT
                                                                                                <span className='ms-3'>
                                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                                </span>
                                                                                            </NavLink>
                                                                                        </div>
                                                                                        <div className='CompanyName'>
                                                                                            <h3 className='mt-3'>
                                                                                                Ultra-Modern Office
                                                                                            </h3>
                                                                                            <p className='pt-1'>
                                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                    New York
                                                                                                </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="carousel-item">
                                                                        <div className="single-item">
                                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                                <div className="col-12">
                                                                                    <div className="image position-relative">
                                                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                            <NavLink to='' className='btnResp'>
                                                                                                VIEW PROJECT
                                                                                                <span className='ms-3'>
                                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                                </span>
                                                                                            </NavLink>
                                                                                        </div>
                                                                                        <div className='CompanyName'>
                                                                                            <h3 className='mt-3'>
                                                                                                Ultra-Modern Office
                                                                                            </h3>
                                                                                            <p className='pt-1'>
                                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                    New York
                                                                                                </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="carousel-item">
                                                                        <div className="single-item">
                                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                                <div className="col-12">
                                                                                    <div className="image position-relative">
                                                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                            <NavLink to='' className='btnResp'>
                                                                                                VIEW PROJECT
                                                                                                <span className='ms-3'>
                                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                                </span>
                                                                                            </NavLink>
                                                                                        </div>
                                                                                        <div className='CompanyName'>
                                                                                            <h3 className='mt-3'>
                                                                                                Ultra-Modern Office
                                                                                            </h3>
                                                                                            <p className='pt-1'>
                                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                    New York
                                                                                                </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div >
                                                </div>
                                            </div>


                                            : display === "CLUB" ?
                                                <div className="container-fluid ">
                                                    <div className="mt-2 p-2 d-none d-sm-block">
                                                        <div className="single-item">
                                                            <div className="row g-4 d-flex" >
                                                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                                                    <div className="image HoverEffect ">
                                                                        <img className='' src={require("../Img/ContentAll4.png")} alt="" />
                                                                        <div className='contentAll '>
                                                                            <h1> Shrushti Hotels and Resort </h1>
                                                                            <p>
                                                                                Pune
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="conatiner-fluid FONT mb-5 pb-5 d-sm-none d-block " >
                                                        <div className=" pt-5 mt-2">
                                                            <div className="single-item">
                                                                <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                    <div className="col-12">
                                                                        <div className="image position-relative">
                                                                            <img className='' src={require("../Img/ContentAll4.png")} alt="" />
                                                                            <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                <NavLink to='' className='btnResp'>
                                                                                    VIEW PROJECT
                                                                                    <span className='ms-3'>
                                                                                        <i className="fa-solid fa-angle-right"></i>
                                                                                    </span>
                                                                                </NavLink>
                                                                            </div>
                                                                            <div className='CompanyName'>
                                                                                <h3 className='mt-3'>
                                                                                Shrushti Hotels and Resort
                                                                                </h3>
                                                                                <p className='pt-1'>
                                                                                    <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                        Pune
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                : display === "OFFICE" ?
                                                    <div className="container-fluid ">
                                                        <div className="mt-2 p-2 d-none d-sm-block">
                                                            <div className="single-item">
                                                                <div className="row g-4 d-flex mb-2" >
                                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                                                        <div className="image HoverEffect ">
                                                                            <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                            <div className='contentAll '>
                                                                                <h1> America’s Cup Building </h1>
                                                                                <p>
                                                                                    Pune
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row g-4 d-flex mt-2" >
                                                                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                                                        <div className="image HoverEffect ">
                                                                            <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                            <div className='contentAll '>
                                                                                <h1> America’s Cup Building </h1>
                                                                                <p>
                                                                                    Pune
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="conatiner-fluid FONT mb-5 pb-5 d-sm-none d-block " >
                                                            <div className=" pt-5 mt-2">
                                                                <div className="single-item">
                                                                    <div className="row ps-3 g-4 w-100 d-flex justify-content-center" >
                                                                        <div className="col-12">
                                                                            <div className="image position-relative">
                                                                                <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                    <NavLink to='' className='btnResp'>
                                                                                        VIEW PROJECT
                                                                                        <span className='ms-3'>
                                                                                            <i className="fa-solid fa-angle-right"></i>
                                                                                        </span>
                                                                                    </NavLink>
                                                                                </div>
                                                                                <div className='CompanyName'>
                                                                                    <h3 className='mt-3'>
                                                                                        Ultra-Modern Office
                                                                                    </h3>
                                                                                    <p className='pt-1'>
                                                                                        <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                            New York
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12">
                                                                            <div className="image position-relative">
                                                                                <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                    <NavLink to='' className='btnResp'>
                                                                                        VIEW PROJECT
                                                                                        <span className='ms-3'>
                                                                                            <i className="fa-solid fa-angle-right"></i>
                                                                                        </span>
                                                                                    </NavLink>
                                                                                </div>
                                                                                <div className='CompanyName'>
                                                                                    <h3 className='mt-3'>
                                                                                        Ultra-Modern Office
                                                                                    </h3>
                                                                                    <p className='pt-1'>
                                                                                        <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                            New York
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    : display === "RESIDENTIAL" ?
                                                        <div className="container-fluid ">
                                                            <div className="mt-2 p-2 d-none d-sm-block">
                                                                <div className="single-item">
                                                                    <div className="row g-4 d-flex mb-2" >
                                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="image HoverEffect ">
                                                                                <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                                <div className='contentAll '>
                                                                                    <h1> America’s Cup Building </h1>
                                                                                    <p>
                                                                                        Pune
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="image HoverEffect ">
                                                                                <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                                <div className='contentAll '>
                                                                                    <h1> America’s Cup Building </h1>
                                                                                    <p>
                                                                                        Pune
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row g-4 d-flex mt-2" >
                                                                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                                                                            <div className="image HoverEffect ">
                                                                                <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                                <div className='contentAll '>
                                                                                    <h1> America’s Cup Building </h1>
                                                                                    <p>
                                                                                        Pune
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="conatiner-fluid FONT mb-5 pb-5 d-sm-none d-block " >
                                                                <div className=" pt-5 mt-2">
                                                                    <div className="single-item">
                                                                        <div className="row ps-3 g-4 w-100 d-flex justify-content-center" >
                                                                            <div className="col-12">
                                                                                <div className="image position-relative">
                                                                                    <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                    <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                        <NavLink to='' className='btnResp'>
                                                                                            VIEW PROJECT
                                                                                            <span className='ms-3'>
                                                                                                <i className="fa-solid fa-angle-right"></i>
                                                                                            </span>
                                                                                        </NavLink>
                                                                                    </div>
                                                                                    <div className='CompanyName'>
                                                                                        <h3 className='mt-3'>
                                                                                            Ultra-Modern Office
                                                                                        </h3>
                                                                                        <p className='pt-1'>
                                                                                            <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                New York
                                                                                            </span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12">
                                                                                <div className="image position-relative">
                                                                                    <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                    <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                        <NavLink to='' className='btnResp'>
                                                                                            VIEW PROJECT
                                                                                            <span className='ms-3'>
                                                                                                <i className="fa-solid fa-angle-right"></i>
                                                                                            </span>
                                                                                        </NavLink>
                                                                                    </div>
                                                                                    <div className='CompanyName'>
                                                                                        <h3 className='mt-3'>
                                                                                            Ultra-Modern Office
                                                                                        </h3>
                                                                                        <p className='pt-1'>
                                                                                            <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                New York
                                                                                            </span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12">
                                                                                <div className="image position-relative">
                                                                                    <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                    <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                        <NavLink to='' className='btnResp'>
                                                                                            VIEW PROJECT
                                                                                            <span className='ms-3'>
                                                                                                <i className="fa-solid fa-angle-right"></i>
                                                                                            </span>
                                                                                        </NavLink>
                                                                                    </div>
                                                                                    <div className='CompanyName'>
                                                                                        <h3 className='mt-3'>
                                                                                            Ultra-Modern Office
                                                                                        </h3>
                                                                                        <p className='pt-1'>
                                                                                            <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                New York
                                                                                            </span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        : display === "SHOPPINGMALL" ?
                                                            <div className="container-fluid ">
                                                                <div className="mt-2 p-2 d-none d-sm-block">
                                                                    <div className="single-item">
                                                                        <div className="row g-4 d-flex" >
                                                                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                                                                <div className="image HoverEffect ">
                                                                                    <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                                    <div className='contentAll '>
                                                                                        <h1> America’s Cup Building </h1>
                                                                                        <p>
                                                                                            Pune
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="conatiner-fluid FONT mb-5 pb-5 d-sm-none d-block " >
                                                                    <div className=" pt-5 mt-2">
                                                                        <div className="single-item">
                                                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                                                <div className="col-12">
                                                                                    <div className="image position-relative">
                                                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                            <NavLink to='' className='btnResp'>
                                                                                                VIEW PROJECT
                                                                                                <span className='ms-3'>
                                                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                                                </span>
                                                                                            </NavLink>
                                                                                        </div>
                                                                                        <div className='CompanyName'>
                                                                                            <h3 className='mt-3'>
                                                                                                Ultra-Modern Office
                                                                                            </h3>
                                                                                            <p className='pt-1'>
                                                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                    New York
                                                                                                </span>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            : display === "STRUCTURE" ?
                                                                <div className="container-fluid ">
                                                                    <div className="mt-2 p-2 d-none d-sm-block">
                                                                        <div className="single-item">
                                                                            <div className="row g-4 d-flex mb-2" >
                                                                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                                                                    <div className="image HoverEffect ">
                                                                                        <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                                        <div className='contentAll '>
                                                                                            <h1> America’s Cup Building </h1>
                                                                                            <p>
                                                                                                Pune
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row g-4 d-flex mt-2" >
                                                                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                                                                                    <div className="image HoverEffect ">
                                                                                        <img className='' src={require("../Img/ContentAll1.png")} alt="" />
                                                                                        <div className='contentAll '>
                                                                                            <h1> America’s Cup Building </h1>
                                                                                            <p>
                                                                                                Pune
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="conatiner-fluid FONT mb-5 pb-5 d-sm-none d-block " >
                                                                        <div className=" pt-5 mt-2">
                                                                            <div className="single-item">
                                                                                <div className="row g-4 ps-3 w-100 d-flex justify-content-center" >
                                                                                    <div className="col-12">
                                                                                        <div className="image position-relative">
                                                                                            <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                            <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                                <NavLink to='' className='btnResp'>
                                                                                                    VIEW PROJECT
                                                                                                    <span className='ms-3'>
                                                                                                        <i className="fa-solid fa-angle-right"></i>
                                                                                                    </span>
                                                                                                </NavLink>
                                                                                            </div>
                                                                                            <div className='CompanyName'>
                                                                                                <h3 className='mt-3'>
                                                                                                    Ultra-Modern Office
                                                                                                </h3>
                                                                                                <p className='pt-1'>
                                                                                                    <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                        New York
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-12">
                                                                                        <div className="image position-relative">
                                                                                            <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                                                            <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                                                                <NavLink to='' className='btnResp'>
                                                                                                    VIEW PROJECT
                                                                                                    <span className='ms-3'>
                                                                                                        <i className="fa-solid fa-angle-right"></i>
                                                                                                    </span>
                                                                                                </NavLink>
                                                                                            </div>
                                                                                            <div className='CompanyName'>
                                                                                                <h3 className='mt-3'>
                                                                                                    Ultra-Modern Office
                                                                                                </h3>
                                                                                                <p className='pt-1'>
                                                                                                    <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                                                        New York
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                : <div>
                                                                    somethings went wrong
                                                                </div>
                                    }
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TernaryOperator