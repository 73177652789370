import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Navbar.css"

const Navbar = () => {
  return (
    <>

      <section className='Top-Header'>
        <section>
          <div className="container-fluid d-md-none d-none d-lg-block " style={{ backgroundColor: "#1a1a1a" }} >
            <div className="container-lg laila-bold pt-3 lato-black" >
              <div className="row g-2 ps-xl-2">
                <ul class=" d-flex justify-content-between align-items-center ms-xl-4 pb-0" style={{ listStyle: "none" }} >
                  <li className="" style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#808080", letterSpacing: "1px" }} >
                  </li>

                  <li className="" style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#808080", letterSpacing: "1px" }} >
                    <i class="fa-sharp fa-regular fa-clock me-lg-1"></i> Monday to Saturday - 9AM to 6PM
                    <span className='SocialIcon  text-center ms-lg-3'>
                      <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                      <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                      <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                      <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="container-fluid d-lg-none d-md-block" style={{ backgroundColor: "#1a1a1a" }} >
            <div className="row g-2 pt-3 pb-3 text-center d-flex justify-content-center align-items-center text-white laila-semibold">
              <div className="col-sm-6 col-12">
                <div className='' style={{ fontSize: "14px", lineHeight: "20px", fontWeight: "400", color: "#808080", letterSpacing: "1px" }}>
                  <i class="fa-sharp fa-regular fa-clock me-lg-1"></i> Monday to Friday - 10AM to 6PM
                </div>
              </div>

              <div className="col-sm-6 col-12">
                <div>
                  <span className='SocialIcon  text-center ms-lg-3'>
                    <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                    <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                    <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                    <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="container-lg laila-semibold mb-2 mt-2" >
              <div className="row  ps-lg-1 g-2 d-flex align-items-center justify-content-around header-logo-contact-section">
                <div className="col-lg-5 LogoStyle">
                  <NavLink to='/'>
                    <div className='mb-1'>
                      <img className='LogoFinalSize' src={require("../Img/UpdateLogo.png")} alt="icloudsoft_logo" />
                    </div>
                  </NavLink>
                  <small className='' style={{ fontSize: "11.5px" }}>
                  We Have Ability That Strength Your Dreams
                  </small>
                </div>

                <div className="col-lg-6">
                  <div className='row d-flex align-items-center justify-content-center '>
                    <div className="col-lg-4 col-md-3">
                      <div className='d-flex justify-content-center'>
                        <div className='fs-4 mt-1'>
                          <i class="fa-sharp fa-solid fa-phone me-1" ></i>
                        </div>
                        <div className='ms-2'>
                          <span className='' style={{ fontSize: "11px", fontWeight: "600", color: '#8b8b8b' }}>
                            Toll Free
                          </span>
                          <NavLink className="text-decoration-none" to='tel:9860843466'>
                            <p className='CommonHeaderSecond' >
                              +91 9860843466
                            </p>
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-3">
                      <div className='d-flex justify-content-center'>
                        <div className='fs-4 mt-1'>
                          <i class="fa-regular fa-envelope me-1"></i>
                        </div>
                        <div className='ms-2'>
                          <span className='' style={{ fontSize: "11px", fontWeight: "600", color: '#8b8b8b' }}>
                            Email
                          </span>
                          <NavLink className="text-decoration-none" to='mailto:info@easc.co.in'>
                            <p className='CommonHeaderSecond' >
                              info@easc.co.in
                            </p>
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-3">
                      <div className='d-flex justify-content-center pt-lg-1'>
                        <NavLink className='text-decoration-none' to='/contact'>
                          <button className='header-logo-contact-section-btn'>
                            GET A QUOTE
                          </button>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className='Bottom-Header'>
        <div className="container-fluid  p-0 d-md-none d-none d-lg-block " style={{ backgroundColor: "#ffd800" }}>
          <nav className="navbar navbar-expand-lg navbar p-0 " >
            <div className="container ">
              <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" ></span>
              </button>
              <div className="collapse navbar-collapse " id="navbarSupportedContent">
                <ul className="navbar-nav d-flex justify-content-start ms-lg-4">
                  {/* <li className="nav-item dropdown pe-lg-4">
                    <NavLink className="nav-link" to='/' >
                      HOME  <span className='ms-1'>
                        <i class="fa-solid fa-angle-down"></i>
                      </span>
                    </NavLink>
                    <ul className="dropdown-menu ">
                      <li className=''>
                        <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/"> Home Variation 2 </NavLink>
                      </li>

                      <li className=''>
                        <NavLink className="dropdown-item text-decoration-none" style={{ fontSize: '12px', fontWeight: '400', color: "#515151" }} to="/rubber"> Home Variation 3 </NavLink>
                      </li>

                    </ul>
                  </li> */}

                  <li className="nav-item pe-lg-4">
                    <NavLink className="nav-link" style={{ color: "#1a1a1a" }} aria-current="page" to="/">HOME</NavLink>
                  </li>

                  <li className="nav-item pe-lg-4">
                    <NavLink className="nav-link " aria-current="page" to="/services">SERVICES</NavLink>
                  </li>

                  <li className="nav-item pe-lg-4">
                    <NavLink className="nav-link " aria-current="page" to="/projects">PROJECTS</NavLink>
                  </li>

                  <li className="nav-item pe-lg-4">
                    <NavLink className="nav-link " aria-current="page" to="/aboutus">ABOUT US</NavLink>
                  </li>

                  <li className="nav-item pe-lg-4">
                    <NavLink className="nav-link " aria-current="page" to="/contact">CONTACT</NavLink>
                  </li>

                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div className="container-fluid p-0 d-none ">
          <nav className="navbar navbar-expand-lg p-0 " >
            <div className="container-fluid p-0 ">
              <div className='LogoSection' >
                <NavLink className="text-decoration-none" to="/">
                  <h4>
                    <span>I</span>
                    cloudsoft
                  </h4>
                </NavLink>
              </div>
              <button className="navbar-toggler" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fa-solid fa-bars " style={{ color: "#2bc0c1" }}></i>
              </button>
              <div className="collapse navbar-collapse w-100 p-0 " id="navbarNavDropdown">
                <ul className="navbar-nav d-flex justify-content-start p-3 pt-4 pb-4 mb-3" style={{ backgroundColor: "#202734", border: "2px solid #2bc0c1", width: "95%", marginLeft: "8px" }} >
                  <li className='nav-item text-start pb-2 mt-2' >
                    <NavLink className="text-white text-decoration-none fs-6  " to="/" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }}> Home </b> </NavLink>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <NavLink className="text-white text-decoration-none fs-6  " to="/services" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > SERVICES </b> </NavLink>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <NavLink className="text-white text-decoration-none fs-6  " to="/about" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > ABOUT US </b> </NavLink>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <NavLink className="text-white text-decoration-none fs-6  " to="/portfolio" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > PORTFOLIO </b> </NavLink>
                  </li>

                  <li className='nav-item text-start pb-2 mt-2' >
                    <NavLink className="text-white text-decoration-none fs-6  " to="/contact" > <b className='' style={{ fontWeight: "600", color: "#2bc0c1" }} > Contact us </b> </NavLink>
                  </li>

                </ul>
              </div>
            </div>
          </nav>
        </div>
      </section>

    </>
  )
}

export default Navbar