import React from 'react'
import "./Silder.css"
import { NavLink } from 'react-router-dom'

const Silder = () => {
    return (
        <>

            <section>
                <div className="container-fluid mb-5 d-none d-sm-block">
                    <div className="container-lg  mt-2">
                        <div className="testimonials ">
                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="single-item">
                                            <div className="row w-100 d-flex justify-content-center" >
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0">
                                                    <div className="image position-relative">
                                                        <img className='' src={require("../Img/MidSilder1.png")} alt="" />
                                                        <div className='position-absolute text-white' style={{ bottom: "1%", left: "3%" }}>
                                                            <h5 className='CommonText'>Shah promoters and builders</h5>
                                                            <p className='CommonTextP'>
                                                                <i class="fa-solid fa-location-dot"></i> <span>
                                                                    Pune
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className='contentFirstSlide'>
                                                            <NavLink to='' className='btn'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0">
                                                    <div className="image position-relative ">
                                                        <img className='' src={require("../Img/MidSilder3.png")} alt="" />
                                                        <div className='position-absolute text-white' style={{ bottom: "1%", left: "3%" }}>
                                                            <h5 className='CommonText'>Design Interiors, Bungalow</h5>
                                                            <p className='CommonTextP'>
                                                                <i class="fa-solid fa-location-dot"></i> <span>
                                                                    Pune
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className='contentFirstSlide'>
                                                            <NavLink to='' className='btn'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="single-item">
                                            <div className="row w-100 d-flex justify-content-center" >
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0">
                                                    <div className="image position-relative">
                                                        <img className='' src={require("../Img/MidSilder3.png")} alt="" />
                                                        <div className='position-absolute text-white' style={{ bottom: "1%", left: "3%" }}>
                                                            <h5 className='CommonText'>Design Interiors, Bungalow</h5>
                                                            <p className='CommonTextP'>
                                                                <i class="fa-solid fa-location-dot"></i> <span>
                                                                    Pune
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className='contentFirstSlide'>
                                                            <NavLink to='' className='btn'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0">
                                                    <div className="image position-relative ">
                                                        <img className='' src={require("../Img/MidSilder4.png")} alt="" />
                                                        <div className='position-absolute text-white' style={{ bottom: "1%", left: "3%" }}>
                                                            <h5 className='CommonText'>Pensive Architecture Company, Bungalow</h5>
                                                            <p className='CommonTextP'>
                                                                <i class="fa-solid fa-location-dot"></i> <span>
                                                                    Pune
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className='contentFirstSlide'>
                                                            <NavLink to='' className='btn'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="carousel-item">
                                        <div className="single-item">
                                            <div className="row w-100 d-flex justify-content-center" >
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0">
                                                    <div className="image ">
                                                        <img className='' src={require("../Img/MidSilder5.png")} alt="" />
                                                        <div className='contentFirstSlide'>
                                                            <NavLink to='' className='btn'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0">
                                                    <div className="image  ">
                                                        <img className='' src={require("../Img/MidSilder6.png")} alt="" />
                                                        <div className='contentFirstSlide'>
                                                            <NavLink to='' className='btn'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span className='LeftArrow'>
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span className='RightArrow'>
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="conatiner-fluid FONT mb-5 pb-5 d-sm-none d-block " style={{ backgroundColor: "#ECECEC" }} >
                    <div className="container-lg pt-5 mt-2">
                        <div className="testimonials ">
                            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="single-item">
                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                <div className="col-12">
                                                    <div className="image position-relative">
                                                        <img className='' src={require("../Img/MidSilder1.png")} alt="" />
                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                            <NavLink to='' className='btnResp'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                        <div className='CompanyName'>
                                                            <h3 className='mt-3'>
                                                                Ultra-Modern Office
                                                            </h3>
                                                            <p className='pt-1'>
                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                    New York
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="single-item">
                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                <div className="col-12">
                                                    <div className="image position-relative">
                                                        <img className='' src={require("../Img/MidSilder2.png")} alt="" />
                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                            <NavLink to='' className='btnResp'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                        <div className='CompanyName'>
                                                            <h3 className='mt-3'>
                                                                Ultra-Modern Office
                                                            </h3>
                                                            <p className='pt-1'>
                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                    New York
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="single-item">
                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                <div className="col-12">
                                                    <div className="image position-relative">
                                                        <img className='' src={require("../Img/MidSilder3.png")} alt="" />
                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                            <NavLink to='' className='btnResp'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                        <div className='CompanyName'>
                                                            <h3 className='mt-3'>
                                                                Ultra-Modern Office
                                                            </h3>
                                                            <p className='pt-1'>
                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                    New York
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="single-item">
                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                <div className="col-12">
                                                    <div className="image position-relative">
                                                        <img className='' src={require("../Img/MidSilder4.png")} alt="" />
                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                            <NavLink to='' className='btnResp'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                        <div className='CompanyName'>
                                                            <h3 className='mt-3'>
                                                                Ultra-Modern Office
                                                            </h3>
                                                            <p className='pt-1'>
                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                    New York
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="single-item">
                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                <div className="col-12">
                                                    <div className="image position-relative">
                                                        <img className='' src={require("../Img/MidSilder5.png")} alt="" />
                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                            <NavLink to='' className='btnResp'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                        <div className='CompanyName'>
                                                            <h3 className='mt-3'>
                                                                Ultra-Modern Office
                                                            </h3>
                                                            <p className='pt-1'>
                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                    New York
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="carousel-item">
                                        <div className="single-item">
                                            <div className="row ps-3 w-100 d-flex justify-content-center" >
                                                <div className="col-12">
                                                    <div className="image position-relative">
                                                        <img className='' src={require("../Img/MidSilder6.png")} alt="" />
                                                        <div className='contentResponsive position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center'>
                                                            <NavLink to='' className='btnResp'>
                                                                VIEW PROJECT
                                                                <span className='ms-3'>
                                                                    <i className="fa-solid fa-angle-right"></i>
                                                                </span>
                                                            </NavLink>
                                                        </div>
                                                        <div className='CompanyName'>
                                                            <h3 className='mt-3'>
                                                                Ultra-Modern Office
                                                            </h3>
                                                            <p className='pt-1'>
                                                                <i className="fa-solid fa-location-dot me-2"></i>   <span className='ms-1'>
                                                                    New York
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    {/* <span className="carousel-control-prev-icon" aria-hidden="true"></span> */}
                                    <span className='LeftArrow'>
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    {/* <span className="carousel-control-next-icon" aria-hidden="true"></span> */}
                                    <span className='RightArrow'>
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div >
                </div>
            </section>



        </>
    )
}

export default Silder