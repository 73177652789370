import React from 'react'
import { NavLink } from 'react-router-dom'

const AsBuilt = () => {
  return (
    <>

    <section>
        <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/ServicesBg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center,center', height: "180px" }}>
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className='ServicesMainFirst'>
                        <h2>
                        As-Built Drawing Preparation
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div className="container-fluid">
            <div className="container-lg FeatureMain">
                <div className="row mb-4">
                    <div className='TopHeader'>
                        <p className='mt-3'>
                            <NavLink className="text-decoration-none" to='/'>
                                <span className='TopHeaderFirst'>
                                    HOME
                                </span>
                            </NavLink>
                            <span className='TopHeaderIcon'>
                                <i class="fa-sharp fa-solid fa-angle-right"></i>
                            </span>
                            <NavLink className="text-decoration-none" to='/services'>
                                <span className='TopHeaderSecond'>
                                    SERVICES
                                </span>
                            </NavLink>
                            <span className='TopHeaderIcon'>
                                <i class="fa-sharp fa-solid fa-angle-right"></i>
                            </span>
                            <span className='TopHeaderSecond'>
                            As-Built Drawing Preparation
                            </span>
                        </p>
                    </div>
                </div>

                <div className="row g-4 pt-2 ITServicesCommon d-flex justify-content-lg-center">
                    <div className="col-lg-8 col-md-10">
                        <div>
                            <img className='w-100' src={require("../Img/AsBuilt1.png")} alt="" />
                        </div>
                        <div className='ManagedItServices'>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry’s standard dummy
                                text ever since the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen book.
                            </p>
                            <h2>
                                Whats Required to Initiate the Process
                            </h2>
                            <p>
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                                dolorem que laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                                veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo
                                enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
                                consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                            </p>
                            <p>
                                <div className="row g-4">
                                    <div className="col-lg-5 col-md-5">
                                        <p>
                                            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                                            consectetur, adipisci velit, sed quia non numquam eius modi tempora
                                            incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                                            Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
                                            suscipit laboris, nisi ut aliquid ex ea commodi consequat. Quis autem
                                            vel eum iure reprehenderit qui in ea voluptate
                                            velit esse quam nihil molestiae consequatur,
                                            vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.
                                        </p>
                                    </div>

                                    <div className="col-lg-7 col-md-7">
                                        <img className='w-100 mt-lg-3' src={require("../Img/RccDesign2.png")} alt="" />
                                    </div>
                                </div>
                            </p>
                            <p>
                                Curabitur blandit tempus porttitor. Vivamus sagittis lacus
                                vel augue laoreet rutrum faucibus dolor auctor. Maecenas
                                faucibus mollis interdum. Aenean eu leo quam. Pellentesque
                                ornare sem lacinia quam venenatis vestibulum. Fusce dapibus,
                                tellus ac cursus commodo, tortor mauris condimentum nibh,
                                ut fermentum massa justo sit amet risus.
                                Cras mattis consectetur purus sit amet fermentum.
                            </p>
                            <h2>
                                The Kind of Deliverables You Should Expect
                            </h2>
                            <p>
                                Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum
                                faucibus dolor auctor. Maecenas faucibus mollis interdum.
                            </p>
                            <p>
                                <ul>
                                    <li>Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.</li>
                                    <li>Cras mattis consectetur purus sit amet fermentum.</li>
                                </ul>
                            </p>
                            <p>
                                Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                                Vestibulum id ligula porta felis euismod semper. Aenean eu
                                leo quam. Pellentesque ornare sem lacinia quam venenatis
                                vestibulum. Cum sociis natoque penatibus et magnis dis parturient
                                montes, nascetur ridiculus mus. Nullam id dolor id
                                nibh ultricies vehicula ut id elit. Integer
                                posuere erat a ante venenatis dapibus posuere velit aliquet.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-10">
                        <div className='ManagedITServicesMainServiceses '>
                            <h2>
                                Services
                            </h2>
                            <NavLink className="text-decoration-none" to='/rccdesign'>
                                <p>
                                    RCC Design
                                </p>
                            </NavLink>
                            <NavLink className="text-decoration-none" to='/structuralsteeldesign'>
                                <p>
                                    Structural Steel Design
                                </p>
                            </NavLink>
                            <NavLink className="text-decoration-none" to='/restoration'>
                                <p>
                                    Restoration / Rehabilitation / Strengthening Works
                                </p>
                            </NavLink>
                            <NavLink className="text-decoration-none" to='/tprv'>
                                <p>
                                    Third Party Review Validation
                                </p>
                            </NavLink>
                            <NavLink className="text-decoration-none" to='/structuralaudit'>
                                <p>
                                    Structural Audit
                                </p>
                            </NavLink>
                            <NavLink className="text-decoration-none" to='/scs'>
                                <p>
                                    Stability Certificate
                                </p>
                            </NavLink>
                            <NavLink className="text-decoration-none" to='/fr'>
                                <p>
                                    Feasibility Report
                                </p>
                            </NavLink>
                            <NavLink className="text-decoration-none" to='/estimation'>
                                <p>
                                Estimation, Costing And Tendering
                                </p>
                            </NavLink>
                            <NavLink className="text-decoration-none" to='/abdp'>
                                <p>
                                As-Built Drawing Preparation
                                </p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</>
  )
}

export default AsBuilt