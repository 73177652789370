import React from 'react'
import "./Footer.css"
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <div className="container-fluid" style={{ backgroundColor: "#1a1a1a" }}>
                <div className="container-lg FeatureMain">
                    <div className="row g-3 d-flex justify-content-center MainText FooterTop">
                        <div className="col-xl-1 col-lg-1 col-md-1">
                            <img className='' src={require("../Img/footer-logo.png")} alt="icloudsoft_logo" />
                        </div>
                        <div className="col-xl-9 col-lg-7 col-md-7">
                            <div className='FooterTopText'>
                                <h6>
                                    Epicentre Provide Innovative And Cost Effective Most Appropriate Solutions For The Civil Engineering Projects
                                    To Meet The Client's Specialized Demands.
                                </h6>
                            </div>
                        </div>

                        <div className="col-xl-2 col-lg-3 col-md-3">
                            <div className='FooterTopBtn'>
                                <button>
                                    Buy now
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center  row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-sm-2 row-cols-1 g-4 MainFooter">
                        <div>
                            <div className='MainContentFooter'>
                                <h3>
                                    OUR SERVICES
                                </h3>
                                <ul>
                                    <li>
                                        <img className='' src={require("../Img/FooterArrow.png")} alt="icloudsoft_logo" />
                                        <a href="/rccdesign" className='text-decoration-none'>
                                            <span>
                                                Rcc Design
                                            </span>
                                        </a>
                                    </li>

                                    <li>
                                        <img className='' src={require("../Img/FooterArrow.png")} alt="icloudsoft_logo" />
                                        <a href="/structuralsteeldesign" className='text-decoration-none'>
                                            <span>
                                                Structural Design Services
                                            </span>
                                        </a>
                                    </li>

                                    <li>
                                        <img className='' src={require("../Img/FooterArrow.png")} alt="icloudsoft_logo" />
                                        <a href="/tprv" className='text-decoration-none'>
                                            <span>
                                                Third Party Review Validation
                                            </span>
                                        </a>
                                    </li>

                                    <li>
                                        <img className='' src={require("../Img/FooterArrow.png")} alt="icloudsoft_logo" />
                                        <a href="/structuralaudit" className='text-decoration-none'>
                                            <span>
                                                Structural Audit
                                            </span>
                                        </a>
                                    </li>

                                    <li>
                                        <img className='' src={require("../Img/FooterArrow.png")} alt="icloudsoft_logo" />
                                        <a href="/scs" className='text-decoration-none'>
                                            <span>
                                                Stability Certificate Service
                                            </span>
                                        </a>
                                    </li>

                                    <li>
                                        <img className='' src={require("../Img/FooterArrow.png")} alt="icloudsoft_logo" />
                                        <a href="/restoration" className='text-decoration-none'>
                                            <span>
                                                Restoration / Rehabilitation ...
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            <div className='MainContentFooterSecond'>
                                <h3>
                                    RECENT PROJECTS
                                </h3>
                                <ul>
                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <img className='' src={require("../Img/FooterSmallimg1.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <span>
                                            Shah promoters and builders
                                        </span>
                                    </li>

                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <img className='' src={require("../Img/FooterSmallimg2.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <span>
                                            Shrushti Hotels and Resort
                                        </span>
                                    </li>

                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <img className='' src={require("../Img/FooterSmallimg3.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <span>
                                            Sai constructions
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            <div className='MainContentFooterSecond'>
                                <h3>
                                    BLOG POSTS
                                </h3>
                                <ul>
                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <img className='' src={require("../Img/FooterSmallimg4.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <span>
                                            Make Running a Part of Your Life
                                        </span>
                                    </li>

                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <img className='' src={require("../Img/FooterSmallimg5.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <span>
                                            Take Breaks to Improve Your Productivity
                                        </span>
                                    </li>

                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <img className='' src={require("../Img/FooterSmallimg6.png")} alt="icloudsoft_logo" />
                                        </div>
                                        <span>
                                            Start Writing a Journal
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            <div className='MainContentFooterThird'>
                                <h3>
                                    GET IN TOUCH
                                </h3>
                                <ul>
                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <i class="fa-solid fa-phone"></i>
                                        </div>
                                        <NavLink className="text-decoration-none" to='tel:9860843466'>
                                            <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                                +91 9860843466
                                            </span>
                                        </NavLink>
                                    </li>

                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <i class="fa-solid fa-phone"></i>
                                        </div>
                                        <NavLink className="text-decoration-none" to='tel:9860843466'>
                                            <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                                +91 9860843466
                                            </span>
                                        </NavLink>
                                    </li>

                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <i class="fa-solid fa-envelope"></i>
                                        </div>
                                        <NavLink className="text-decoration-none" to='mailto:info@easc.co.in'>
                                            <span style={{ fontSize: "14px", fontWeight: "400" }}>
                                                info@easc.co.in
                                            </span>
                                        </NavLink>
                                    </li>

                                    <li className='SecondSection d-flex'>
                                        <div>
                                            <i class="fa-solid fa-location-dot"></i>
                                        </div>
                                        <span className='' style={{ cursor: "initial", color: "#b8b8b8" }}>
                                            2nd Floor, Row House No.3,Trimuti Chowk,Behind Trimuti Heights,Near Maratha Mandir,Bavdhan,Pune,Maharashtra 411021
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid d-none d-sm-block" style={{ backgroundColor: "#111" }}>
                <div className="container-lg FooterLastSection">
                    <div className="d-flex justify-content-between align-items-lg-center" >
                        <div className='text-center' >
                            © Copyright2024 <b className='text-white'> EpicenterConsultants </b> All Rights Reserved
                        </div>
                        <div className='text-center  text-white ' style={{ fontSize: '10px' }} >
                            <span className='SocialIcon  text-center '>
                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                                <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid d-sm-none d-block" style={{ backgroundColor: "#111" }}>
                <div className="container-lg FooterLastSection">
                    <div className="row g-3 d-flex justify-content-between align-items-center" >
                        <div className='col-12 text-center' style={{ letterSpacing: "1px", lineHeight: "20px" }} >
                            © Copyright2024 <b className='text-white'> EpicenterConsultants </b> All Rights Reserved
                        </div>

                        <div className='col-12 text-center  text-white ' style={{ fontSize: '10px' }} >
                            <span className='SocialIcon'>
                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                                <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                                <NavLink to="https://www.facebook.com/icloudsofttechnology " target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer